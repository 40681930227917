.css-1ld3b9g-MuiGrid-root {
  margin-left: 0 !important;
}
.css-1ld3b9g-MuiGrid-root > .MuiGrid-item {
  padding-left: 0 !important;
}
.css-1ld3b9g-MuiGrid-root {
  width: auto !important;
}
.css-169iwlq-MuiCalendarPicker-root {
  width: auto !important;
}


.css-195y93z-MuiButtonBase-root-MuiPickersDay-root.Mui-selected{
  background-color: #189EC9 !important;
}

.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root.Mui-selected{
  background-color: #189EC9 !important;
}
